<template>
  <v-container>

    <div class="login">
      <v-form>
      <v-card  width="500" height="auto"  class="elevation-3 mx-auto text-center mt-1 pa-5" style="border-radius: 30px;">
        <v-img 
          class="mx-auto pt-10" width="45%" contain
          src="@/assets/images/onespring_logo.svg"
        ></v-img>
            <v-text-field outlined rounded 
              class="mx-auto mt-10 "
              style="height: 45px; width: 70%; " 
              prepend-inner-icon="mdi-account-circle"
              label="아이디"
              ref="account" 
              v-model="account"
              color="#3a4f3a"
            />
            <v-text-field outlined rounded
              class="mx-auto mt-5 "
              style="height: 45px; width: 70%; " 
              prepend-inner-icon="mdi-lock" 
              :type="showPassword ? 'text' : 'password'" 
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="비밀번호"
              ref="password" 
              v-model="password"
              @click:append="showPassword = !showPassword"
              color="#3a4f3a"
            />

        <v-card-actions class="d-block">

          <v-btn depressed  width="70%" height="60"
            id="login" 
            class="rounded-pill mt-5 elevation-7"  
            type="submit" 
            @click.prevent="clickLogin()">
            로그인
          </v-btn>

          <v-spacer class="pt-5"></v-spacer>

          <v-btn outlined width="20%" height="40" color="#88191a"
            id="sign_up"  
            class="rounded-pill"
            href="/user/joinagree">회원가입
          </v-btn>

          <v-btn width="25%" text color="#666666"
            id="find_id"  
            class="rounded-pill"  
            href="/user/findid">
            아이디 찾기
          </v-btn>
          
          <v-btn width="20%" text color="#666666"
            id="find_password"
            class="rounded-pill"
            href="/user/findpassword">
            비밀번호 찾기 
          </v-btn>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>
      </v-form>

    </div>

  </v-container>
</template>

<script>
  import dateUtil from "@/utils/custom/dateProperty.js";
  export default {
    name: 'Login',

    data() {
      return {
        account : "",
        password : "",
        showPassword: false
      };
    },
    mounted() {
      this.$refs.account.focus();
    },

    methods : {
      // ...mapMutations(['doLogin']),
      clickLogin() {
        // console.log("login submit");
        let account = this.account;
        let password = this.password;
        if (!account) {
          alert("아이디를 입력하세요.");
          this.$refs.account.focus();
          return;
        }
        if (!password) {
          alert("비밀번호를 입력하세요.");
          this.$refs.password.focus();
          return;
        }

        this.$dispatch("login", {
          session_id: this.getSessionId(account),
          account: account,
          password: password,
        }).then((res) => {
          if (res.result) {
            // 로그인 성공 시 환영메세지 팝업 노출
              // console.log("권한:",this.$getters.userInfo.grade);
            // alert(this.$getters.userName + "님 안녕하세요.");
            // this.$router.push("/monitoring/summary").catch((e) => {
            this.$router.push("/home").catch((e) => {
              console.log(e.message);
            });
          } else {
            alert("ID , 비밀번호를 확인하세요. ");
            this.account = "";
            this.password = "";
            this.$refs.account.focus();
            // console.log(res);
          }
        }).catch( (err) => {  // 개별 API 오류 처리 예제
            console.log("LogIn 호출 오류",err);
            alert(err);
            this.$router.push("/user/login");
            }) ;
            // alert("님 안녕하세요.");

      },

      getSessionId(account) {
        let _sessionId = `${this.getNation()}/${this.getPageId()}/${this.getLocalTime()}/${account}////${this.getUtcTime()}`;
        // console.log(_sessionId);
        return _sessionId;
      },
      getNation() {
        let type = navigator.appName;
        let lang = "";
        let nation = "KOR"; // 기본값 한국
        if (type === "Netscape") {
          lang = navigator.language;
        } else {
          lang = navigator.userLanguage.substr(0, 2);
        }

        // TODO: 국가코드 정해달라고 해야됨
        switch (lang) {
          case "zh": // 중국
            nation = "CHN";
            break;
          case "en": // 미국
            nation = "ENG";
            break;
          default:
            break;
        }
        return nation;
      },
      getPageId() {
        // TODO: 화면아이디 지금 다 바뀌어서 다시 정해야될듯
        return "000010";
      },
      getLocalTime() {
        return dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss");
      },
      getUtcTime() {
        // 1. 현재 시간(Locale)
        const curr = new Date();
        // console.log("현재시간(Locale) : " + curr);

        // 2. UTC 시간 계산
        const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

        // TODO: 시간 형식 ISO 표준으로 하는게 맞을지 문서에 나온규격대로 짜르는게 맞을지?
        // return new Date(utc).toISOString();
        return dateUtil.format(new Date(utc), "yyyy-MM-dd HH:mm:ss");
      },


    }
  }
</script>

<style lang="scss" scoped>
*{
  letter-spacing: -1px;
}
#login {
  border-radius: 5px;
  border-color: #3a4f3f;
  border: solid 1px #3a4f3f;
  background-color:#3a4f3f;
  align-content:center;
  font-size: 20px;
  font-weight: bold; color: #ffffff;
  }
</style>